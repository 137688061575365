import React, { useEffect, useState, useContext } from 'react'
import { Loader } from 'react-bulma-components';
import axios from 'axios'
import CartContext from '../../context/CartContext'

import { calculateOrderAmount, calculateTaxAmount, createOrderItems } from '../../utils/helper'

export default () => {
  const [loading, setLoading] = useState(true)
  const { products } = useContext(CartContext)

  const setCheckoutFrameFromResponse = res => {
    localStorage.setItem('orderId', JSON.stringify(res.data.order_id))
    const checkoutContainer = document.getElementById('checkout-container')
    checkoutContainer.innerHTML = res.data.html_snippet
    const scriptsTags = checkoutContainer.getElementsByTagName('script')
    for (let i = 0; i < scriptsTags.length; i++) {
      const parentNode = scriptsTags[i].parentNode
      const newScriptTag = document.createElement('script')
      newScriptTag.type = 'text/javascript'
      newScriptTag.text = scriptsTags[i].text
      parentNode.removeChild(scriptsTags[i])
      parentNode.appendChild(newScriptTag)

      setLoading(false)
    }
  }

  useEffect(
    () => {
      const orderId = localStorage.getItem('orderId')
      const orderAmount = calculateOrderAmount(products)
      const orderTaxAmount = calculateTaxAmount(orderAmount)
      const orderItems = createOrderItems(products)

      const orderData = {
        orderId,
        orderAmount,
        orderTaxAmount,
        orderItems
      }

      axios
        .post(`/.netlify/functions/checkout-${orderId ? 'update' : 'create'}`, orderData)
        .then(res => {
          if (res.status === 200) {
            setCheckoutFrameFromResponse(res)
          }
        })
        .catch(err => {
          // Create new checkout order if checkout update fails
          axios
            .post('/.netlify/functions/checkout-create', orderData)
            .then(res => {
              if (res.status === 200) {
                setCheckoutFrameFromResponse(res)
              }
            })
            .catch(err => {
              console.log(err)
            })
        })
    },
    [products]
  )

  return (
    <div>
      {loading && (
        <Loader
          style={{
            width: 50,
            height: 50,
            border: '2px solid #2980b9',
            borderTopColor: 'transparent',
            borderRightColor: 'transparent',
            margin: '0 auto'
          }}
        />
      )}

      <div id="checkout-container" />
    </div>
  )
}
