import React, { useContext } from "react";
import axios from "axios";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaTrash } from "react-icons/fa";
import SEO from "../components/SEO";
import KlarnaCheckout from "../components/KlarnaCheckout";
import CartContext from "../context/CartContext";
import { calculateOrderAmount, calculateTaxAmount, createOrderItems } from "../utils/helper";

import { Heading, Container, Section, Box } from "react-bulma-components";

const Checkout = () => {
  const { products, removeProduct, updateQuantity } = useContext(CartContext);

  // const updateQuantityInCartAndCheckout = (product, quantity) => {
  //   updateQuantity(product, quantity)
  //   updateCartAndCheckout()
  // }

  const removeProductFromCartAndCheckout = (product) => {
    removeProduct(product);

    // TODO: fix ugly solution to handle state update in checkout
    let updatedProducts = products.filter((p) => {
      if (p.Id === product.Id) {
        if (p.SelectedSize === product.SelectedSize) {
          return false;
        }
      }

      return true;
    });

    updateCartAndCheckout(updatedProducts);
  };

  const updateCartAndCheckout = (updatedProducts) => {
    window._klarnaCheckout(function (api) {
      api.suspend();
    });

    const orderId = localStorage.getItem("orderId");
    const orderAmount = calculateOrderAmount(updatedProducts);
    const orderTaxAmount = calculateTaxAmount(orderAmount);
    const orderItems = createOrderItems(updatedProducts);

    const orderData = {
      orderId,
      orderAmount,
      orderTaxAmount,
      orderItems,
    };

    axios
      .post("/.netlify/functions/checkout-update", orderData)
      .then(() => {
        window._klarnaCheckout(function (api) {
          api.resume();
        });
      })
      .catch((err) => {
        console.log("Error while updating Klarna checkout order");
      });
  };

  let cartItems;
  if (products.length > 0) {
    cartItems = products.map((product, i) => (
      <div className="cart-item" key={i}>
        <div className="cart-item__img">
          <Link to={product.Slug}>
            <GatsbyImage alt="Product image" image={product.Images.localFiles[0].childImageSharp.gatsbyImageData} />
          </Link>
        </div>

        <div className="cart-item__content">
          <div className="cart-item__top-content">
            <span className="cart-item__name">{product.Name}</span>
            <span className="remove-btn" title="Ta bort" onClick={() => removeProductFromCartAndCheckout(product)}>
              <FaTrash />
            </span>
          </div>
          <div className="cart-item__info">
            <div className="cart-item__size">
              {product.Measure === "cm" && (
                <>
                  {product.SelectedSize} {product.Measure}
                </>
              )}
            </div>
            <div className="cart-item__qty">
              <span className="cart-item__qty-amount">{product.Qty} st</span>
            </div>
            <div className={`cart-item__price ${product.IsSalePrice ? "cart-item__price--sale" : ""}`}> {product.Price * product.Qty} kr </div>
          </div>
        </div>
      </div>
    ));
  }

  return (
    <>
      <SEO title="Kassa | Wallang" />
      <Container>
        <Section>
          <Heading className="has-text-centered">Varor i kundvagnen</Heading>

          {products.length > 0 && (
            <>
              <Box className="checkout-cart-box">{cartItems}</Box>

              <Box className="checkout-cart-box checkout-cart-list checkout-cart-list__sum">
                <span>Summa:</span>
                <span>{products.reduce((totalSum, product) => (totalSum += product.Price * product.Qty), 0)} kr</span>
              </Box>
            </>
          )}

          {products.length === 0 && (
            <Box className="checkout-cart-box checkout-cart-list checkout-cart-list__sum">
              <span>Du har inga varor i kundvagnen.</span>
            </Box>
          )}
        </Section>
      </Container>
      <hr />
      {products.length > 0 && (
        <Container>
          <Section>
            <Heading className="has-text-centered">Slutför köp</Heading>
            <KlarnaCheckout />
          </Section>
        </Container>
      )}
    </>
  );
};

export default Checkout;
